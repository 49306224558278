import * as React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import theme from '../design-system/theme';
import SvgTnrIcon from '../design-system/icons/js/TnrIcon';
import Button from './Button';
import BackToTopLink from './BackToTopLink';

const Footer = ({ content: { html, getInTouchButton }, commonContent: { backToTop } }) => (
	<StyledFooter>
		<SvgTnrIcon />
		<div dangerouslySetInnerHTML={{ __html: html }} />

		<a href="mailto:hello@thenewradiance.com">
			<StyledButton active big>
				{getInTouchButton}
			</StyledButton>
		</a>

		<BackToTopLink label={backToTop} />
	</StyledFooter>
);

Footer.propTypes = {
	content: PropTypes.shape({
		getInTouchButton: PropTypes.string.isRequired,
		html: PropTypes.string.isRequired
	}).isRequired,
	commonContent: PropTypes.shape({
		backToTop: PropTypes.string.isRequired
	}).isRequired
};

const StyledButton = styled(Button)`
	font-size: inherit;
`;

const StyledFooter = styled.footer`
	box-sizing: border-box;
	width: ${theme.dimensions.contentWidthSmall};
	margin: 0 auto;
	text-align: left;

	@media (min-width: ${theme.breakpoints.tablet}px) {
		width: ${theme.dimensions.contentWidthLarge};
	}

	> svg {
		display: block;
		width: 60px;
		height: 60px;
		margin: 80px auto 0;
		color: ${theme.colors.icon};

		@media (min-width: ${theme.breakpoints.tablet}px) {
			margin-top: 100px;
		}
	}

	hr {
		margin-top: 80px;
		margin-bottom: 18px;
	}

	${StyledButton} {
		margin-top: 30px;
		margin-bottom: 40px;
	}
`;

export default Footer;
