import * as React from 'react';
import { graphql } from 'gatsby';
import orderBy from 'lodash/orderBy';
import StartPage from '../components/start-page/StartPage';

const IndexContainer = props => {
	const {
		data: {
			pageContent: {
				childMarkdownRemark: { frontmatter: pageFrontmatter }
			},
			footerContent: {
				childMarkdownRemark: { html: footerHtml, frontmatter: footerFrontmatter }
			},
			commonContent: {
				childMarkdownRemark: { frontmatter: commonFrontmatter }
			},
			cases: { edges: rawCases }
		}
	} = props;
	const cases = orderBy(
		rawCases
			.map(rawCase => ({ ...rawCase.node.frontmatter, ...rawCase.node.fields }))
			.filter(caseData => caseData.visibleInGrid),
		['gridOrder', 'year', 'title'],
		['desc', 'desc', 'asc']
	);
	return (
		<StartPage
			content={pageFrontmatter}
			footerContent={{ ...footerFrontmatter, html: footerHtml }}
			commonContent={commonFrontmatter}
			cases={cases}
		/>
	);
};

export const pageQuery = graphql`
	query IndexPageQuery {
		pageContent: file(sourceInstanceName: { eq: "pages" }, name: { eq: "start" }) {
			childMarkdownRemark {
				frontmatter {
					title
					taglineShort
					taglineLong
				}
			}
		}
		footerContent: file(sourceInstanceName: { eq: "pages" }, name: { eq: "footer" }) {
			childMarkdownRemark {
				html
				frontmatter {
					getInTouchButton
				}
			}
		}
		commonContent: file(sourceInstanceName: { eq: "pages" }, name: { eq: "common" }) {
			childMarkdownRemark {
				frontmatter {
					categories
					backToTop
				}
			}
		}
		cases: allMarkdownRemark(filter: { fields: { contentType: { eq: "cases" } } }) {
			edges {
				node {
					fields {
						slug
					}
					frontmatter {
						title
						year
						client
						categories
						tags
						teaserImage
						gridOrder
						visibleInGrid
					}
				}
			}
		}
	}
`;

export default IndexContainer;
