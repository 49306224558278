import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SEO from '../seo';
import Layout from '../Layout';
import StartPageHeader from './StartPageHeader';
import CasesGrid from './CasesGrid';
import Footer from '../Footer';
import CmsRedirect from '../CmsRedirect';

export default class StartPage extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedFilter: null
		};
	}

	handleFilter = filter => {
		this.setState({
			selectedFilter: filter
		});
	};

	render() {
		const { content, footerContent, commonContent, cases } = this.props;
		const { selectedFilter } = this.state;
		const filteredCases =
			selectedFilter && selectedFilter !== commonContent.categories[0]
				? cases.filter(projectCase => projectCase.categories.includes(selectedFilter))
				: cases;
		return (
			<StyledStartPage>
				<SEO title="The New Radiance" />
				<CmsRedirect />
				<StartPageHeader
					title={content.title}
					taglineShort={content.taglineShort}
					taglineLong={content.taglineLong}
					filters={commonContent.categories}
					onFilter={this.handleFilter}
				/>
				<CasesGrid cases={filteredCases} />
				<Footer content={footerContent} commonContent={commonContent} />
			</StyledStartPage>
		);
	}
}

StartPage.propTypes = {
	content: PropTypes.shape({
		title: PropTypes.string.isRequired,
		taglineShort: PropTypes.string.isRequired,
		taglineLong: PropTypes.string.isRequired
	}).isRequired,
	footerContent: PropTypes.shape({
		getInTouchButton: PropTypes.string.isRequired,
		html: PropTypes.string.isRequired
	}).isRequired,
	commonContent: PropTypes.shape({
		backToTop: PropTypes.string.isRequired
	}).isRequired,
	cases: PropTypes.arrayOf(PropTypes.object).isRequired
};

const StyledStartPage = styled(Layout)`
	padding-bottom: 100px;
`;
