import * as React from 'react';
import styled from '@emotion/styled';
import * as PropTypes from 'prop-types';
import SvgTnrLogo from '../../design-system/icons/js/TnrLogo';
import theme from '../../design-system/theme';
import Filters from './Filters';

export default class StartPageHeader extends React.PureComponent {
	render() {
		const { title, taglineShort, taglineLong, filters, onFilter } = this.props;
		return (
			<StyledStartPageHeader>
				<SvgTnrLogo />
				<h1>{title}</h1>
				<div>
					<p className="small-viewport-only" dangerouslySetInnerHTML={{ __html: taglineShort }} />
					<p className="large-viewport-only" dangerouslySetInnerHTML={{ __html: taglineLong }} />

					<Filters className="filters" filters={filters} onFilter={onFilter} />
				</div>
			</StyledStartPageHeader>
		);
	}
}

StartPageHeader.propTypes = {
	title: PropTypes.string.isRequired,
	taglineShort: PropTypes.string.isRequired,
	taglineLong: PropTypes.string.isRequired,
	filters: PropTypes.arrayOf(PropTypes.string).isRequired,
	onFilter: PropTypes.func.isRequired
};

const StyledStartPageHeader = styled.header`
	width: ${theme.dimensions.contentWidthSmall};
	margin: 0 auto;
	text-align: center;

	@media (min-width: ${theme.breakpoints.tablet}px) {
		width: ${theme.dimensions.contentWidthLarge};
	}

	h1 {
		position: absolute;
		top: 0;
		left: 0;
		opacity: 0;
		pointer-events: none;
	}

	svg {
		display: block;
		width: 160px;
		padding: 38px 0 13px 0;
		margin: 0 auto;
		color: ${theme.colors.logo};

		@media (min-width: ${theme.breakpoints.tablet}px) {
			width: 240px;
			padding: 60px 0 20px 0;
		}
	}

	> div {
		padding: 8px 0 12px 0;
		border-top: 1px solid ${theme.colors.logo};

		@media (min-width: ${theme.breakpoints.tablet}px) {
			padding: 16px 0 24px 0;
		}
	}

	p {
		margin: 0 0 15px 0;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			margin: 0 0 24px 0;
		}
	}
`;
