import * as React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/core';
import theme from '../design-system/theme';

export default ({ className, children, active, big, onClick }) => (
	<StyledButton className={className} active={active} big={big} onClick={onClick}>
		<span>{children}</span>
	</StyledButton>
);

const StyledButton = styled.div`
	${({ active, big }) => css`
		width: ${big ? '160px' : '80px'};
		height: ${big ? '40px' : '28px'};
		margin: 0 auto;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 11px;
		background-color: ${active ? theme.colors.primary : theme.colors.buttons};
		color: ${active ? theme.colors.black : theme.colors.white};
		border-radius: 4px;
		cursor: pointer;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			width: ${big ? '160px' : '96px'};
			height: ${big ? '40px' : '32px'};
		}
	`};
`;
