import React from 'react';
import * as PropTypes from 'prop-types';

export default class CmsRedirect extends React.PureComponent {
	componentDidMount() {
		document.addEventListener('keydown', this.handleKeyDown);
	}

	componentWillUnmount() {
		document.removeEventListener('keydown', this.handleKeyDown);
	}

	handleKeyDown = event => {
		const { caseSlug } = this.props;
		if (event.key === 'e' && event.ctrlKey) {
			event.preventDefault();
			if (caseSlug) {
				document.location.href = `/admin/#/collections/cases/entries/${caseSlug}`;
			} else {
				document.location.href = '/admin/';
			}
		}
	};

	render() {
		return null;
	}
}

CmsRedirect.propTypes = {
	caseSlug: PropTypes.string
};
