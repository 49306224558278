import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import SvgArrowUp from '../design-system/icons/js/ArrowUp';
import theme from '../design-system/theme';

export default class BackToTopLink extends React.PureComponent {
	handleClick = () => {
		window.scrollTo({ top: 0, behavior: 'smooth' });
	};

	render() {
		const { className, label } = this.props;
		return (
			<StyledBackToTopLink className={className} onClick={this.handleClick}>
				<SvgArrowUp />
				<span>{label}</span>
			</StyledBackToTopLink>
		);
	}
}

BackToTopLink.propTypes = {
	className: PropTypes.string,
	label: PropTypes.string.isRequired
};

const StyledBackToTopLink = styled.div`
	display: block;
	text-align: center;
	background-color: rgba(0, 0, 0, 0);
	cursor: pointer;

	> svg {
		width: 24px;
		height: 24px;
		display: block;
		margin: 0 auto;
		color: ${theme.colors.icon};
	}

	> span {
		display: block;
		margin: 0 auto;
	}
`;
