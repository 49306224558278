import * as React from 'react';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import Button from '../Button';
import theme from '../../design-system/theme';

export default class Filters extends React.PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			selectedFilter: 'All Work',
			isExpanded: false
		};
	}

	handleButtonClick = filter => {
		const { onFilter } = this.props;
		const { selectedFilter, isExpanded } = this.state;
		if (!isExpanded && filter === selectedFilter) {
			this.setState({
				isExpanded: true
			});
			return;
		}
		this.setState({
			isExpanded: false,
			selectedFilter: filter
		});
		onFilter(filter);
	};

	renderForLargeViewports = () => {
		const { filters, className } = this.props;
		const { selectedFilter } = this.state;

		return (
			<StyledFilters className={`${className} large-viewport-only`}>
				{filters.map(filter => (
					<Button key={filter} active={filter === selectedFilter} onClick={() => this.handleButtonClick(filter)}>
						{filter}
					</Button>
				))}
			</StyledFilters>
		);
	};

	renderForSmallViewports = () => {
		const { filters, className } = this.props;
		const { selectedFilter, isExpanded } = this.state;
		const [noFilter, ...caseFilters] = filters;

		return (
			<StyledFilters className={`${className} small-viewport-only`} expanded={isExpanded}>
				<Button active onClick={() => this.handleButtonClick(selectedFilter)}>
					{selectedFilter}
				</Button>
				<div className="group">
					<div>
						{caseFilters.map(filter => {
							const currentFilter = filter === selectedFilter ? noFilter : filter;
							return (
								<Button key={currentFilter} onClick={() => this.handleButtonClick(currentFilter)}>
									{currentFilter}
								</Button>
							);
						})}
					</div>
				</div>
			</StyledFilters>
		);
	};

	render() {
		return (
			<React.Fragment>
				{this.renderForLargeViewports()}
				{this.renderForSmallViewports()}
			</React.Fragment>
		);
	}
}

Filters.propTypes = {
	filters: PropTypes.arrayOf(PropTypes.string).isRequired,
	onFilter: PropTypes.func
};

const StyledFilters = styled.div`
	margin: 0 -4px;

	@media (min-width: ${theme.breakpoints.tablet}px) {
		&.large-viewport-only {
			display: flex;
		}
	}

	> div:first-of-type {
		margin: 0 auto;
		margin-bottom: 16px;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			flex: 0 0 auto;
			margin: 4px 4px;
		}
	}

	div {
		margin: 4px 4px;
		width: 80px;
		height: 29px;
		user-select: none;
		transition: background-color 250ms;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			width: 96px;
		}
	}

	.group {
		box-sizing: border-box;
		width: 100%;
		height: ${({ expanded }) => (expanded ? '82px' : '0')};
		margin: 0 auto;
		overflow: hidden;
		transition: height 600ms ${theme.easings.easeOutQuart};

		> div {
			width: 100%;
			margin: -4px 0;
			display: flex;
			flex-flow: row wrap;
			justify-content: center;
			align-items: flex-start;
		}
	}
`;
