import * as React from 'react';
import { Link } from 'gatsby';
import * as PropTypes from 'prop-types';
import styled from '@emotion/styled';
import theme from '../../design-system/theme';

const CaseTeaser = ({ teaserImage, title, year, client, categories, slug }) => (
	<StyledCaseTeaser>
		<Link to={`case/${slug}`}>
			<div className="image">
				<svg viewBox="0 0 100 100">
					<g />
				</svg>
				<img src={teaserImage} alt="" />
			</div>
			<div className="text">
				<strong>{title}</strong>
				<br />
				{year}, <span>{client}</span>,{' '}
				{categories.map((category, index) => (
					<React.Fragment key={category}>
						<span>
							{category}
							{index < categories.length - 1 ? ',' : ''}
						</span>{' '}
					</React.Fragment>
				))}
			</div>
		</Link>
	</StyledCaseTeaser>
);

CaseTeaser.propTypes = {
	teaserImage: PropTypes.string,
	title: PropTypes.string,
	year: PropTypes.string,
	client: PropTypes.string,
	categories: PropTypes.arrayOf(PropTypes.string),
	slug: PropTypes.string
};

const StyledCaseTeaser = styled.article`
	> a {
		color: inherit;
	}

	.image {
		position: relative;
		overflow: hidden;
		border-radius: 4px;
		font-size: 0;

		> svg {
			width: 100%;
		}

		> img {
			display: block;
			position: absolute;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
		}
	}

	.text {
		margin-top: 16px;

		span {
			white-space: nowrap;
		}
	}
`;

export default CaseTeaser;
