import * as React from 'react';
import styled from '@emotion/styled';
import CaseTeaser from './CaseTeaser';
import theme from '../../design-system/theme';

export default ({ cases }) => (
	<StyledCasesGrid>
		<div>
			{cases.map(caseData => (
				<CaseTeaser key={caseData.slug} {...caseData} />
			))}
		</div>
	</StyledCasesGrid>
);

const StyledCasesGrid = styled.section`
	> div {
		box-sizing: border-box;
		display: flex;
		padding: 0 8px;
		flex-flow: row wrap;
		justify-content: flex-start;
		align-items: flex-start;
		margin: 0 auto;

		@media (min-width: ${theme.breakpoints.tablet}px) {
			padding: 0;
			max-width: ${720 + 20 + 20}px;
		}

		@media (min-width: ${theme.breakpoints.large}px) {
			max-width: 70%;
		}

		@media (min-width: ${theme.breakpoints.superLarge}px) {
			max-width: 1728px;
		}

		> article {
			box-sizing: border-box;
			flex: 0 0 50%;
			padding: 16px 8px 24px;

			@media (min-width: ${theme.breakpoints.tablet}px) {
				padding: 30px 20px;
			}

			@media (min-width: ${theme.breakpoints.large}px) {
				padding: 30px 1.5625% 22px;
				flex-basis: 33.33333%;
			}
		}
	}
`;
